// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$PlataformaInscripciones-primary: mat.define-palette(mat.$red-palette, 900);
$PlataformaInscripciones-accent: mat.define-palette(mat.$cyan-palette, 900);

// The warn palette is optional (defaults to red).
$PlataformaInscripciones-warn: mat.define-palette(mat.$gray-palette);

// The warn palette is optional (defaults to red).
$PlataformaInscripciones-edit: mat.define-palette(mat.$gray-palette, 800);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$PlataformaInscripciones-theme: mat.define-light-theme((color: (primary: $PlataformaInscripciones-primary,
        accent: $PlataformaInscripciones-accent,
        warn: $PlataformaInscripciones-warn,
        basic: $PlataformaInscripciones-edit )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($PlataformaInscripciones-theme);

$materialize-fonts-path: '~materialize-angular/fonts';

@font-face {
  font-family: 'MonMedio';
  font-weight: 400;
  src: url(assets/fonts/Montserrat/Montserrat-Medium.ttf)
      format('ttf');
  font-display: swap;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  vertical-align: middle;
  font-family: "MonMedio";
}

.panel {
  border-style: solid;
  border-color: mat.get-color-from-palette($PlataformaInscripciones-primary, 900);
  border-radius: 5px;
}

.panel-heading {
  background-color: mat.get-color-from-palette($PlataformaInscripciones-primary, 900);
  color: white;
}

.panel-body {
  padding-left: 1em;
  padding-bottom: 1em;
}

.panel-title {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  font-size: 20px;
}

.btn-menu{
  background-color: mat.get-color-from-palette($PlataformaInscripciones-primary, 800);
}


@import "~bootstrap/dist/css/bootstrap.css"